import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import Fade from "react-reveal/Fade"
import { Icon } from "semantic-ui-react"
import { grey, lightBlack, skyBlue, white } from "../../constants/colors"
import { gillFont } from "../../constants/fonts"
import { bigDesktop, desktop, smallMobile, tablet } from "../../constants/screenSizes"
import BorderedButton from "../../components/button/BorderedButton"
import ColorWrapper from "../../components/landing-page/ColorWrapper"
import staticBackgroundStyles from "../../components/landing-page/staticBackgroundStyles"
import Block from "../../components/landing-page/Block"
import InnerBlock from "../../components/landing-page/InnerBlock"
import Part from "../../components/landing-page/Part"
import Title from "../../components/landing-page/Title"
import LargeTitle from "../../components/landing-page/LargeTitle"
import Subheading from "../../components/landing-page/Subheading"
import { Grid } from "../../components/landing-page/Grid"
import Description from "../../components/landing-page/Description"
import TeamGiftingFaqAccordion from "./TeamGiftingFaqAccordion"
import useIntersectionObserver from "../../hooks/useIntersectionObserver"
import CompilingAddresses from "../../../images/compiling-addresses.jpg"
import ClaimAGift from "../../../images/claim-a-gift.jpg"
import IconCollectionGift from "../../../svg/icon-collection-gift.inline.svg"
import IconSelectGift from "../../../svg/icon-select-gift.inline.svg"
import IconTrackImpact from "../../../svg/icon-track-impact.inline.svg"
import IllustrationRipple from "../../../svg/illustration-ripple.inline.svg"
import LPCorporateQuotesBlock from "../../components/landing-page/new/components/reviews/LPCorporateQuotesBlock"
import EnquiryFormModal from "../../components/landing-page/new/components/lead-capture/enquiry-form/EnquiryFormModal"
import LPBanner5 from "../../components/landing-page/new/components/banner/LPBanner5"

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CheckIcon = styled(Icon)`
  color: ${skyBlue};
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 140px;
  justify-content: space-between;

  @media (max-width: ${desktop}px) {
    display: flex;
    flex-direction: row;
    height: unset;
    width: 430px;
  }

  @media (max-width: ${smallMobile}px) {
    width: 100%;
    flex-wrap: wrap;
  }
`

const StyledButton = styled(BorderedButton)`
  justify-content: space-between;
  width: 240px;

  @media (max-width: ${desktop}px) {
    margin-top: 15px;
    font-size: 16px;
    width: 195px;
  }

  @media (max-width: ${smallMobile}px) {
    &:first-child {
      margin-right: 15px;
    }
    font-size: 14px;
    width: 180px;
  }
`

const WhoAreSocialDesktopTitle = styled.div`
  padding-bottom: 25px;

  @media (max-width: ${desktop}px) {
    display: none;
  }
`
const WhoAreSocialTabletTitle = styled.div`
  display: none;

  @media (max-width: ${desktop}px) {
    display: block;
  }
`

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  @media (max-width: ${bigDesktop}px) {
    margin: 30px 0;
  }
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const GridItem = styled.div`
  width: 100%;
  max-width: 428px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  svg {
    max-width: 100%;
  }

  @media (max-width: ${desktop}px) {
    svg {
      max-width: 80%;
      height: 150px;
    }
  }

  @media (max-width: ${smallMobile}px) {
    svg {
      order: 2;
    }
  }
`

const GridDescriptionWrapper = styled.div`
  display: flex;
  margin-top: 30px;

  @media (max-width: ${tablet}px) {
    align-self: flex-start;
    margin-top: 40px;
  }

  @media (max-width: ${smallMobile}px) {
    order: 1;
    margin: 0 0 20px 0;
  }
`

const GridItemNumber = styled.div`
  background: ${skyBlue};
  height: 43px;
  min-width: 43px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${gillFont};
  font-size: 24px;
  line-height: 32px;
  color: ${white};

  @media (max-width: ${desktop}px) {
    height: 30px;
    min-width: 30px;
    font-size: 16px;
    line-height: 28px;
  }

  @media (max-width: ${desktop}px) {
    height: 30px;
    min-width: 30px;
    font-size: 16px;
    line-height: 28px;
  }
`

const GridDescription = styled(Description)`
  margin-left: 20px;

  @media (max-width: ${desktop}px) {
    margin-left: 10px;
  }
`

const EnquiryFormLink = styled.u`
  cursor: pointer;
`

const IllustrationRippleContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 182px;

  @media (max-width: ${tablet}px) {
    height: 129px;
  }

  @media (max-width: ${smallMobile}px) {
    height: 91px;
  }
`

const IllustrationBackground = styled(IllustrationRipple)`
  width: 664px;
  height: 182px;

  @media (max-width: ${tablet}px) {
    width: 471px;
    height: 129px;
  }

  @media (max-width: ${smallMobile}px) {
    width: 332px;
    height: 91px;
  }
`

const BenefitBlockTop = styled(Block)`
  padding: 100px 40px 50px;

  @media (max-width: ${bigDesktop}px) {
    padding: 80px 40px 30px;
  }

  @media (max-width: ${tablet}px) {
    padding: 80px 28px 30px;
  }

  @media (max-width: ${smallMobile}px) {
    padding: 80px 20px 20px;
  }
`

const BenefitBlock = styled(Block)`
  padding: 50px 40px;

  @media (max-width: ${bigDesktop}px) {
    padding: 30px 40px;
  }

  @media (max-width: ${tablet}px) {
    padding: 30px 28px;
  }

  @media (max-width: ${smallMobile}px) {
    padding: 20px;
  }
`

const BenefitBlockBottom = styled(Block)`
  padding: 50px 40px 100px;

  @media (max-width: ${bigDesktop}px) {
    padding: 30px 40px 80px;
  }

  @media (max-width: ${tablet}px) {
    padding: 30px 28px 80px;
  }

  @media (max-width: ${smallMobile}px) {
    padding: 20px 20px 80px;
  }
`

const BenefitPart = styled(Part)<{ order?: number }>`
  @media (max-width: ${desktop}px) {
    order: ${({ order }) => order};
  }
`

const BenefitSubheadingDesktop = styled(Subheading)`
  @media (max-width: ${desktop}px) {
    display: none;
  }
`

const BenefitSubheadingTablet = styled(Subheading)`
  margin-bottom: 33px;
  display: none;

  @media (max-width: ${desktop}px) {
    display: inline-flex;
  }
`

const StyledCorporateQuotesBlock = styled(Block)`
  padding-bottom: 60px;

  @media (max-width: ${bigDesktop}px) {
    padding-bottom: 40px;
  }

  @media (max-width: ${tablet}px) {
    padding-bottom: 40px;
  }

  @media (max-width: ${smallMobile}px) {
    padding-bottom: 40px;
  }
`

const mastheadListItems = [
  "No need to collect delivery addresses",
  "Give your recipient a choice of gifts",
  "Meet everyone’s unique preferences and dietary needs",
  "Plans starting from as little as £25/month",
]

const howItWorkItems = [
  {
    SVG: IconCollectionGift,
    description: "Create a collection of gifts within your budget.",
  },
  {
    SVG: IconSelectGift,
    description:
      "Let recipients select their gift from your curated selection and input their address details.",
  },
  {
    SVG: IconTrackImpact,
    description: "Track the social impact created by your order.",
  },
]

const getBookDemoBlocks = (openEnquiryForm: () => void) => ({
  gotQuestion: {
    subheading: "Got a question?",
    header: "We’re here to support you through every step of the process.",
    description: (
      <>
        Speak to one of our team and we would be pleased to get you set up or answer any questions
        you may have. Alternatively, why not drop us a note through our{" "}
        <EnquiryFormLink onClick={openEnquiryForm}>enquiry form</EnquiryFormLink>.
      </>
    ),
  },
  howToGetStarted: {
    subheading: "how to get started?",
    header: "The best way to get started is to book a demo with one of the team.",
    description: (
      <>
        Got questions on the Gifting Platform? Book a call with one of our team and we would be
        pleased to help. Alternatively, why not drop us a note through our{" "}
        <EnquiryFormLink onClick={openEnquiryForm}>enquiry form</EnquiryFormLink>.
      </>
    ),
  },
  getStartedNow: {
    subheading: "Get started now",
    header: "Want to get started? Contact sales to set up a demo.",
    description: (
      <>
        We can’t wait to help you make your gifting more easy and impactful.
        <EnquiryFormLink onClick={openEnquiryForm}>Contact Sales</EnquiryFormLink>.
      </>
    ),
  },
})

const benefits = {
  whatAreTheBenefits: {
    header: "Ditch address spreadsheets",
    description: `Let your recipients input the address where they’d like to receive their gift — and
      ditch long-winded address spreadsheets for good. You’ll simply need to login to approve
      your recipients’ gift selections – making getting around GDPR woes easy. We’ll let you know when 
      you have new selections to approve.`,
  },
  letThemChooseAGift: {
    header: "Let them choose their gift",
    description: `Send your recipients an invite to claim a gift you’d like to send them, allowing them to
            easily accept or decline. No payment is required until they accept. They can even choose
            from a collection of gifts you create within your budget. We have items that meet every
            dietary need and preference – that means no more difficult decisions for you as to how
            to please everyone.`,
  },
  seeTheSocialImpact: {
    header: "See the social impact your gifts create",
    description: `We measure the impact of every order you place across several social and environmental
            impact areas. This means that as you place orders, your company’s unique impact will be
            aggregated and viewable at a glance in your personal dashboard. From creating jobs for those 
            furthest from the workplace to repurposing materials that would otherwise go to 
            landfill – these gifts all do good.`,
  },
}

const renderBenefitOverview = ({
  header,
  description,
}: {
  header: string
  description: string
}) => (
  <>
    <LargeTitle color={lightBlack} margin="20px 0 10px">
      {header}
    </LargeTitle>
    <Description color={lightBlack}>{description}</Description>
  </>
)

const renderBookDemoBlock = ({
  subheading,
  header,
  description,
  buttonLabel = "Book a demo",
}: {
  subheading: string
  header: string
  description: string | JSX.Element
  buttonLabel?: string
}) => (
  <ColorWrapper background={skyBlue}>
    <Block>
      <InnerBlock>
        <Part width="70%">
          <Subheading isBordered borderColor={white} color={white}>
            {subheading}
          </Subheading>
          <LargeTitle margin="25px 0 10px 0" color={white}>
            {header}
          </LargeTitle>
          <Description color={white}>{description}</Description>
        </Part>
        <Part width="218px" padding="65px 0 0 0" wrapPadding="40px 0 0 0">
          <BorderedButton to="https://meetings.hubspot.com/jessica-burns" icon="arrow right">
            {buttonLabel}
          </BorderedButton>
        </Part>
      </InnerBlock>
    </Block>

    <IllustrationRippleContainer>
      <IllustrationBackground />
    </IllustrationRippleContainer>
  </ColorWrapper>
)

const TeamGiftingPage: React.FC = (): JSX.Element => {
  const [videoUrl, setVideoUrl] = React.useState<string>(
    "https://www.youtube.com/embed/HucDsinx-PA?autoplay=0"
  )
  const videoContainerRef = React.useRef<HTMLDivElement>(null)
  const [showModal, setShowModal] = React.useState<boolean>()

  const onVideoEnterViewport = () => {
    setVideoUrl("https://www.youtube.com/embed/HucDsinx-PA?autoplay=1&mute=1")
  }

  const onVideoLeaveViewport = () => {
    setVideoUrl("https://www.youtube.com/embed/HucDsinx-PA?autoplay=0")
  }

  useIntersectionObserver(
    videoContainerRef,
    "",
    1,
    onVideoEnterViewport,
    onVideoLeaveViewport,
    false
  )

  const openEnquiryForm = () => {
    setShowModal(true)
  }

  const { gotQuestion, howToGetStarted, getStartedNow } = getBookDemoBlocks(openEnquiryForm)

  return (
    <Layout fullWidth>
      <SEO
        title="Team gifting platform"
        description="Team gifting platform"
        image="https://data.socialsupermarket.org/wp-content/uploads/2018/10/Screenshot-2021-09-16-at-14.55.27.png"
      />

      <LPBanner5
        title={"Save time on gifting and create positive change"}
        checklistItems={[
          "No need to collect delivery addresses",
          "Give your recipient a choice of gifts",
          "Meet everyone’s unique preferences and dietary needs",
          "Plans starting from as little as £40/month",
        ]}
        actionLabel={"Contact Sales"}
        action={openEnquiryForm}
      />
      <Block>
        <InnerBlock padding="0 65px 0 0">
          <Part width="500px" padding="25px 0 0 0">
            <WhoAreSocialTabletTitle>
              <Subheading isBordered borderColor={skyBlue}>
                What is the gifting platform?
              </Subheading>
            </WhoAreSocialTabletTitle>
            <VideoWrapper ref={videoContainerRef}>
              <StyledIframe src={videoUrl} allow="autoplay; encrypted-media" frameBorder="0" />
            </VideoWrapper>
          </Part>
          <Part width="40%">
            <WhoAreSocialDesktopTitle>
              <Subheading isBordered borderColor={skyBlue}>
                What is the gifting platform?
              </Subheading>
            </WhoAreSocialDesktopTitle>
            <Title>Our Team Gifting Platform is an easier way to send gifts with impact.</Title>
            <Description margin="10px 0 40px 0">
              We’ve taken the hassle out of gift giving and made it easy to select multiple gift
              options for employees and clients, while showcasing the social impact of these
              products.
            </Description>
            <BorderedButton
              to="https://meetings.hubspot.com/jessica-burns"
              color={skyBlue}
              secondaryColor={white}
              icon="arrow right"
            >
              Book a demo
            </BorderedButton>
          </Part>
        </InnerBlock>
      </Block>

      <ColorWrapper background={grey}>
        <Block>
          <Subheading color={lightBlack} isBordered borderColor={skyBlue}>
            How does the platform work?
          </Subheading>
          <Grid>
            {howItWorkItems.map(({ SVG, description }, i) => (
              <GridItem>
                <SVG />
                <GridDescriptionWrapper>
                  <GridItemNumber>0{i + 1}</GridItemNumber>
                  <GridDescription>{description}</GridDescription>
                </GridDescriptionWrapper>
              </GridItem>
            ))}
          </Grid>
        </Block>
      </ColorWrapper>
      {renderBookDemoBlock(gotQuestion)}

      <BenefitBlockTop>
        <InnerBlock padding="0 65px 0 0">
          <BenefitPart width="46%" padding="35px 0 0 0">
            <BenefitSubheadingTablet color={lightBlack} isBordered borderColor={skyBlue}>
              What are the benefits?
            </BenefitSubheadingTablet>
            <img src={CompilingAddresses} alt="Compiling addresses" />
          </BenefitPart>
          <BenefitPart width="46%">
            <BenefitSubheadingDesktop color={lightBlack} isBordered borderColor={skyBlue}>
              What are the benefits?
            </BenefitSubheadingDesktop>
            {renderBenefitOverview(benefits.whatAreTheBenefits)}
          </BenefitPart>
        </InnerBlock>
      </BenefitBlockTop>

      <BenefitBlock>
        <InnerBlock padding="0 0 0 65px">
          <BenefitPart width="46%" padding="20px 0 0" wrapPadding="20px 0 0" order={2}>
            {renderBenefitOverview(benefits.letThemChooseAGift)}
          </BenefitPart>
          <BenefitPart width="46%" order={1}>
            <img src={ClaimAGift} alt="Claim a gift" />
          </BenefitPart>
        </InnerBlock>
      </BenefitBlock>

      <BenefitBlockBottom>
        <InnerBlock padding="0 65px 0 0">
          <BenefitPart width="46%">
            <img
              src="https://data.socialsupermarket.org/wp-content/uploads/2018/10/media-see-the-social-impact@2x.gif"
              alt="Social Impact"
            />
          </BenefitPart>
          <BenefitPart width="46%" padding="20px 0 0" wrapPadding="20px 0 0">
            {renderBenefitOverview(benefits.seeTheSocialImpact)}
          </BenefitPart>
        </InnerBlock>
      </BenefitBlockBottom>

      {renderBookDemoBlock(howToGetStarted)}
      <LPCorporateQuotesBlock />
      <ColorWrapper background={grey}>
        <Block>
          <TeamGiftingFaqAccordion />
        </Block>
      </ColorWrapper>
      <EnquiryFormModal
        toggle={setShowModal}
        show={showModal}
        source={"Inbound Enquiry - Gifting"}
        name={"GiftingEnquiryForm"}
      />
    </Layout>
  )
}

export default TeamGiftingPage
