import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import staticBackgroundStyles from "../../../staticBackgroundStyles"
import { StaticImage } from "gatsby-plugin-image"
import LPFeatureTicks from "../selling-points/LPFeatureTicks"
import BorderedButton from "../../../../button/BorderedButton"
import { screenSizes } from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  width: 100%;
  position: relative;
  padding: 100px 50px;
  background-image: url("https://data.socialsupermarket.org/wp-content/uploads/2018/10/landing-masthead-scaled.jpg");
  background-size: cover;

  @media (max-width: ${screenSizes.tablet}px) {
    padding: 75px 50px;
  }
`
const Body = styled.div`
  color: white;
  z-index: 100;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: ${screenSizes.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const Left = styled.div`
  margin-right: 20px;
  @media (max-width: ${screenSizes.tablet}px) {
    margin-bottom: 40px;
  }
`
const StyledBrderedButton = styled(BorderedButton)`
  min-width: 250px;
  @media (max-width: ${screenSizes.mobile}px) {
    && {
      width: 100%;
    }
  }
`
const Right = styled.div``
const Title = styled.h1`
  font-family: Minion serif;
  color: white;
  margin-bottom: 30px;
  font-size: 2.2em;

  @media (max-width: ${screenSizes.tablet}px) {
    font-size: 2em;
  }
`

interface Props {
  title: string
  checklistItems: string[]
  actionLabel: string
  action: () => void
}

const LPBanner5: FC<Props> = ({ title, checklistItems, actionLabel, action }) => {
  return (
    <Container>
      <Body>
        <Left>
          <Title>{title}</Title>
          <LPFeatureTicks listItems={checklistItems} />
        </Left>
        <StyledBrderedButton onClick={action}>{actionLabel}</StyledBrderedButton>
      </Body>
    </Container>
  )
}

export default LPBanner5
